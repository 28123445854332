<template>
	<div class="page">
		<div class="w1000">
			<topHeader @goBack="goBack()"></topHeader>

			<div class="top-title-col">
				<span>听写记录</span>
				<span>共<span style="color: #f00">{{total}}</span>词</span>
			</div>

			<!-- 筛选组 -->
			<!-- <div class="filter-list">
				<div class="filter-item" :class="{ 'checked-item': typeChecked === index }" @click="changeType(index)"
					v-for="(item, index) in selectTypes" :key="index">
					<span>{{ item }}</span>
					<div class="line" v-if="typeChecked === index"></div>
				</div>
			</div> -->

			<div class="flex-block" v-if="dataList.length>0">
				<!-- 列表 -->
				<div class="word-list">
					<div class="listbox" v-for="(itemOut, listIdx) in dataList" :key="listIdx">
						<!-- 时间列 -->
						<div class="time-item" @click="handleopenword(listIdx)">
							<div class="time">{{itemOut.time}}</div>
							<div class="words-num">
								共<span style="color: #f00">{{itemOut.list.length}}</span>词
							</div>
							<i class="el-icon-arrow-down" v-if="itemOut.isopen"></i>
							<i class="el-icon-arrow-right" v-else></i>
						</div>
						<div v-if="itemOut.isopen">
							<!--  eslint-disable  -->
							<div class="word-item" :class="{ 'word-checked': wordobj.wordId === item.wordId }" v-for="(item, index) in itemOut.list"
								:key="index">
								<!-- <img src="@/assets/static/linsten_icon.png" alt="" /> -->
								<!-- 单词 -->
								<div class="word-col" @click="handleword(item)">
									<div>{{item.word}}</div>
									<div class="word-desc">
										<span class="desc1" v-for="(sitem, sindex) in item.definitionList"
											:key="sindex">{{ sitem.part }}
											<span class="desc2" v-for="(mitem, mindex) in sitem.means"
												:key="mindex">{{ mitem }}
											</span>
										</span>
									</div>
								</div>
								<!-- <img src="@/assets/static/word_icon.png" alt="" />
								<img src="@/assets/static/star_icon_checked.png" v-if="index === 1" desc="已收藏" alt="" />
								<img src="@/assets/static/star_icon.png" v-else desc="未收藏" alt="" /> -->
							</div>
						</div>
					</div>
					<div style="display: flex;justify-content: center;margin-top: 20px;" v-if="dataList.length>0&&!complete">
					    <el-button @click="getdatalist">点击加载更多</el-button>
					</div>
				</div>
				<!-- 词卡 -->
				<wordCard v-if="isShowWordCard" class="word-card" :wordId="wordobj.wordId"></wordCard>
			</div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 68px;" v-if="dataList.length<=0">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text">暂无数据</div>
			</div>
			<!-- 空 -->
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
	import {mapState} from "vuex";
	import topHeader from "@/components/topHeader/topHeader.vue";
	import wordCard from "@/components/wordCard/wordCard.vue";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		components: {
			topHeader,
			wordCard,
		},
		data() {
			return {
				selectTypes: ["新学", "复习"],
				typeChecked: 0,
				dataList:[],
				currentPage:1,
				total:0,//全部
				complete:false,//完成
				isShowWordCard: false, // 是否进入词卡页面
				wordobj:{},//点击的单词
			};
		},
		computed: {
			...mapState(["userInfo"]),
		},
		created() {
			//数据
			this.getdatalist()
		},
		methods: {
			goBack() {
				this.$router.back();
			},
			changeType(index) {
				this.typeChecked = index;
			},
			//选择打开更多
			handleopenword(index){
				let dataList = this.dataList
				dataList[index].isopen = !dataList[index].isopen
				this.dataList = dataList
			},
			//选择单词
			handleword(item){
				this.wordobj = item
				this.isShowWordCard = true
			},
			//数据
			getdatalist(){
				var _this = this
				if(this.complete){
					return false;
				}
				var params = {
					userUuid: this.userInfo.uuid,
					pageSize:58,
					currentPage:this.currentPage,
				}
				showLoading()
				this.$http.post('app/word/userDictationWord/index2', params).then(function(res) {
					//数据处理
					hideLoading()
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							var newrecords = _this.dealworddate(records)
							//处理格式，
							if (_this.dataList.length > 0) {
								const lastElementOfArray1 = _this.dataList[_this.dataList.length - 1];
								const firstElementOfArray2 = newrecords[0];
								// 比较最后一个元素的time属性和第一个元素的time属性
								if (lastElementOfArray1.time == firstElementOfArray2.time) {
								    // 如果相同，合并list
								    lastElementOfArray1.list = lastElementOfArray1.list.concat(firstElementOfArray2.list);
									_this.dataList[_this.dataList.length - 1] = lastElementOfArray1
								    // 如果不需要保留第二个数组的第一个元素，可以从数组中移除它
								    newrecords.shift();
									_this.dataList  = _this.dataList.concat(newrecords)
								}else{
									_this.dataList  = _this.dataList.concat(newrecords)
								}
							}else{
								_this.dataList  = newrecords
							}
							_this.currentPage +=1
							_this.total = res.data.total
							if(records.length<58){
								_this.complete = true
							}
						}else{
							_this.complete = true
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//处理格式，
			dealworddate(data){
				// 使用reduce方法按时间分组
				const groupedByTime = data.reduce((acc, item) => {
				  const time = item.createDate;
				  if (!acc[time]) {
				    acc[time] = [];
				  }
				  acc[time].push(item);
				  return acc;
				}, {});
				
				// 将分组对象转换为数组，并按时间戳降序排序
				const sortedGroupedArrays = Object.entries(groupedByTime)
				  .sort((a, b) => new Date(b[0]) - new Date(a[0])) // 按时间降序排序
				  .map(([time, group]) => ({ time, list: group })); // 转换为所需格式
				
				sortedGroupedArrays.forEach((witem, windex) => {
					witem["isopen"] = false
					witem.list.forEach((item, index) => {
						item["definitionList"] = []
						try{
							item["definitionList"] = JSON.parse(item.definition)
						}catch(e){
							item["definitionList"] = []
							//TODO handle the exception
						}
					});
				});
				//console.log(sortedGroupedArrays);
				return sortedGroupedArrays;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/css/main.scss";
	
	.top-title-col {
		display: flex;
		background: #e3f0ff;
		align-items: center;
		font-size: 14px;
		justify-content: space-between;
		height: 40px;
		padding-left: 16px;
		padding-right: 24px;
		border-radius: 8px;
		margin-top: 16px;
	}
	
	.filter-list {
		display: flex;
		flex-wrap: nowrap;
		margin: 32px 0px 0 24px;
	
		.checked-item {
			font-weight: bold;
			color: #222222 !important;
		}
	
		.filter-item {
			padding: 0 4px;
			height: 40px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 40px;
			font-size: 16px;
			color: #666666;
			position: relative;
			margin-right: 28px;
			cursor: pointer;
	
			&:hover {
				color: #444;
			}
		}
	
		.line {
			height: 2px;
			width: 24px;
			position: absolute;
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 2px;
			background: #51d9a5;
		}
	}
	
	.word-list {
		max-height: 688px;
		flex: 1;
		overflow-y: auto;
		padding: 20px;
		margin-right: 10px;
		border-radius: 8px;
		box-shadow: 0px 3px 10px 0px #0000001a;
		flex-shrink: 0;
		height: 100%;
		.listbox{
			padding-bottom: 10px;
		}
		.time-item {
			height: 50px;
			border-bottom: solid 1px #e5e5e5;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: 0.3s all;
			color: #222;
			font-weight: bold;
			font-size: 16px;
	
			&:hover {
				border-bottom: solid 1px #67adff;
			}
	
			.words-num {
				margin-left: auto;
				margin-right: 2px;
				color: #666666;
				font-size: 14px;
				font-weight: normal;
			}
		}
	
		.word-checked {
			box-shadow: 0px 3px 15.5px 0px #0000001a;
			background: #fff !important;
		}
	
		.word-item {
			width: 100%;
			display: flex;
			align-items: center;
			height: 74px;
			margin: 16px 10px 0px 0px;
			background: #f7f7f7;
			border-radius: 8px;
			cursor: pointer;
			transition: all 0.3s;
			border: #f7f7f7 1px solid;
			padding: 0 12px;
	
			.word-col {
				flex: 1;
				div {
					@include text-line(1);
					font-size: 16px;
					color: #222222;
					font-weight: bold;
					&:last-child {
						margin-top: 4px;
						font-size: 12px;
						color: #999999;
						font-weight: normal;
					}
				}
				.word-desc{
					width: 100%;
				}
			}
	
			img {
				transition: all 0.3s;
				margin-right: 12px;
				&:hover {
					transform: scale(1.05);
				}
			}
	
			&:hover {
				border: #e2e2e2 1px solid;
			}
		}
	
		&::-webkit-scrollbar {
			width: 6px;
		}
	
		&:hover {
			&::-webkit-scrollbar-thumb {
				background: #cfcfcf; // 滑块颜色
			}
		}
	
		&::-webkit-scrollbar-thumb {
			background: #efefef; // 滑块颜色
			border-radius: 5px; // 滑块圆角
		}
	
		&::-webkit-scrollbar-thumb:hover {
			background: #c2c2c2; // 鼠标移入滑块变红
		}
	
		&::-webkit-scrollbar-track {
			border-radius: 6px; // 轨道圆角
			background-color: #f7f7f7; // 轨道颜色
		}
	}
	
	.flex-block {
		display: flex;
		margin-top: 16px;
	
		.word-card {
			background: #f7f7f7;
		}
	}
</style>